@import "../../../scss/variables";
@import "../../../scss/mixins";
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/core/src/common/variables";

.menu {
	margin: 0; min-width: 240px; user-select: none; border-radius: $base-radius;

	&.pillbutton { min-width: 132px; font-size: $font-size-smaller; text-align: center; }

	&-layout {
		&-inline {
			@include defaultMargin(); font-size: $font-size-medium;
			> LI {
				margin-top: 0;
				+ LI { margin-top: 1px; }
			}
			.menu-item { padding: 10px 20px; }
			.#{$ns}-menu-item-label {
				font-weight: $bold; margin-left: 10px;
				STRONG { display: block; color: $color-danger; text-align: right; }
				DEL { display: block; font-weight: normal; font-size: $font-size-small; text-align: right; }
			}
		}
	}
}
