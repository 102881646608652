@import "mixins";
@import "variables";

BODY { font-size: $base-font-size; line-height: $base-line-height; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

H1, H2, H3, H4, H5, H6 {
	line-height: 1.2; font-weight: 400; margin: $gridunit 0 0 0; padding: 0;
	+ HR,
	+ .longpage { margin-top: 16px; }
}

P { margin: $base-margin; }

H1 { font-size: $font-size-largest; font-weight: $medium; }
H2 { font-size: $font-size-larger; font-weight: $medium; }
H3 { font-size: $font-size-large; font-weight: $medium; }
H4 { font-size: $font-size-medium; font-weight: $bold; }
H5 { font-size: $font-size-medium; font-weight: normal; }
H6 { font-size: $font-size-small; font-weight: $bold; }

@include mobile {
	H1 { font-size: 18px; font-weight: 600; }
}

* { font-variant-numeric: lining-nums !important; }
