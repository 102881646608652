@import "mixins";
@import "variables";

// Search box

.box-search {
	@include defaultMargin(); @include defaultPadding(); @include clearfix();
	background-color: $color-white; box-shadow: $shadow01; border-radius: $base-radius;

	@include mobile {
		.form-row-group {
			padding: 0; margin: 0; display: block;
			&-item { margin: 0; }
			.button,
			> .dropzone { width: 100%; max-width: 100%; margin: $gridunit / 4 0 0 0; }
			.dropzone,
			.dropzone-root { display: block; }
		}
	}
}

// Promo box

.box-promo {
	background-color: $color-gray-lighter; background-size: cover; background-position: 50% 0; background-repeat: no-repeat; color: $color-text-dark;
	position: relative; z-index: 0; text-align: center; font-size: 17px; line-height: 1.5; box-sizing: border-box; min-height: 540px;
	flex: 1 0 auto; display: flex; flex-direction: column;

	&.small { min-height: 300px; }
	&.standalone {
		@include defaultMargin(); border-radius: $base-radius;
	}

	H2, H3, H4, H5, H6 { font-weight: $medium; margin: 0; color: inherit; font-size: 48px; line-height: 52px; }
	SUP { font-size: 30%; vertical-align: 0; transform: translate(0, -20%); position: absolute; }

	.pre-heading { margin: 0; font-size: 24px; font-weight: $medium; }
	.has-image { color: rgba(0, 0, 0, 0) !important; background-size: contain; background-position: 50% 50%; background-repeat: no-repeat; }

	@include mobile {
		H2, H3, H4, H5, H6 { font-size: 36px; line-height: 42px; }
		.pre-heading { font-size: 18px; }
	}

	UL:not([class]) {
		padding: 0; margin: $gridunit 0 0 0;
		> LI {
			padding: 0; margin: 0;
			&:before { display: none; }
			+ LI {
				padding-top: $gridunit;
				&:before {
					display: block; width: 10px; height: 1px; left: 50%; top: 10px; border-radius: 0; transform: translate(-50%, 0); opacity: 0.2;
				}
			}
		}
	}

	&__text {
		flex: 0 0 auto; padding: 50px;
		@include mobile {
			padding: $gridunit;
		}
	}
	&__image {
		flex: 1 1 auto; margin: 0 50px 50px 50px;
		@include mobile {
			margin: 0 $gridunit $gridunit $gridunit;
		}
		background-size: contain; background-position: 50% 50%; background-repeat: no-repeat;
		&.no-margin { margin: 0 !important; }
	}

	&__actions {
		text-align: center; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
		&.vertical { flex-direction: column; }
		A {
			padding: 8px 16px; line-height: $gridunit; border-radius: 18px; position: relative;
			&:not(.primary) {
				&:after { content: ""; position: absolute; left: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(45deg) scale(0.8); transform-origin: 50% 50%; }
			}
			&.primary {
				background-color: $color-link; color: $color-white !important;
				&:hover { background-color: $color-link-hover; text-decoration: none; }
			}
		}
	}

	// Wide size

	@at-root .layout-grid__container.wide & {
		@include not-mobile {
			flex-direction: row; flex-wrap: nowrap;
			&__text { flex: 0 0 450px; align-self: center; }
			&__image { flex: 1 1 auto; margin: 50px 50px 50px 0; }

			&.reversed { flex-direction: row-reverse; }
			&.reversed .box-promo__image { margin: 50px 0 50px 50px; }
		}
	}

	// On gray background

	@at-root .gray & {
		background-color: $color-white;
	}

	// Alignment

	&.align-horizontal-left {
		text-align: left;
	}
	&.align-vertical-center {
		align-items: center; justify-content: center;
	}

	// Text themes

	&.text-white {
		color: $color-white;
		A { color: #2997ff; }
	}
	&.text-white &__actions {
		A {
			color: $color07;
			&.primary { background-color: $color07; }
		}
	}

	// Action themes

	&.actions-white &__actions {
		A {
			color: $color-white;
			&.primary { background-color: $color-white; color: $color07 !important; }
		}
	}
}

// Utilities

.longtext {
	font-size: 17px; line-height: 1.47059;
	P, HR { @include defaultMargin(); }
}

// Tech specs

.tech-specs {
	display: flex; flex-direction: row; flex-wrap: nowrap; margin: 25px 0 0 0;

	& + & { border-top: 1px solid $color-gray-lighter; padding-top: 5px; }

	&__title {
		flex: 0 0 300px; font-size: 24px; line-height: 30px; font-weight: $medium; margin-right: $gridunit; padding-top: 15px;
		&.has-icon {
			background-size: 60px 60px; background-repeat: no-repeat; background-position: 0 10px; min-height: 80px; padding: 10px 0 10px 80px;
			display: flex; align-items: center; align-self: flex-start; box-sizing: border-box;
		}
		.price { display: block; font-weight: 400; font-size: 80%; }
	}
	&__description { flex: 1 1 auto; }

	@include mobile {
		display: block;
		&__title { font-size: 18px; line-height: 24px; margin: 0; }
	}

	TABLE:not([class]) {
		margin-top: 15px;
		THEAD TR:first-child {
			TH, TD { padding-top: 0; border-top: 0; }
		}
		TBODY TR:last-child {
			TH, TD { padding-bottom: 0; border-bottom: 0; }
		}
	}
}

// Paginator

.paginator {
	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; font-size: 16px; color: $color-gray-medium; @include defaultMargin();
	INPUT {
		outline: none; width: 34px; height: 34px; border: none; box-shadow: inset 0 0 0 1px $color-gray-light; border-radius: 5px; text-align: center; color: $color-gray-medium; padding: 0; margin: 0 1ex 0 0; font-size: 16px; line-height: 34px; box-sizing: border-box;
		&:focus {
			box-shadow: inset 0 0 0 1px $color-gray-medium, 0 0 0 3px rgba($color-link, 0.2); color: $color-text-dark;
		}
	}
	&__counter {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center; margin: 0 50px;
	}
	A {
		width: 36px; height: 36px; border-radius: 50%; overflow: hidden; display: block; transition: all $uispeed $uieasing 0ms; position: relative; color: inherit;
		&:hover { background-color: $color-gray-lightest; }
		SPAN { display: none; }
		&:before { content: ""; position: absolute; left: 50%; top: 50%; width: 14px; height: 14px; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform-origin: 50% 50%; }
	}
	&__prev:before { transform: translate(-40%, -50%) scaleX(0.85) rotate(-135deg) scale(0.8); }
	&__next:before { transform: translate(-60%, -50%) scaleX(0.85) rotate(45deg) scale(0.8); }
}

// Cart

.cart-table {
	width: 100%; box-sizing: border-box; margin: 0; border-collapse: collapse; border-spacing: 0;
	TH, TD {
		padding: $gridunit 10px; margin: 0; border: none; text-align: left; vertical-align: top; border-top: 1px solid $color-gray-light;
		&:first-child { padding-left: 0; }
		&:last-child { padding-right: 0; }
	}

	TR:first-child {
		TH, TD { border-top: 0 none; }
	}

	P { margin: 0; }

	.sum { text-align: right; white-space: nowrap; }

	.image { display: block; width: 150px; height: 150px; background: $color-white 50% 50% no-repeat; background-size: contain; }

	&-image {
		A { display: block; }
	}

	&-title {
		font-weight: $bold; font-size: $font-size-large; line-height: 1.5;
		A {
			color: inherit;
			&:hover { color: $color-link; }
		}
	}

	&-config {
		margin: $gridunit 0 0 0; display: block; border-radius: $base-radius; overflow: hidden;
		> LI {
			padding: 0; margin: 0;
			& + LI { margin-top: -1px; }
			&:first-child LABEL SPAN { border-radius: $base-radius $base-radius 0 0; }
			&:last-child LABEL SPAN { border-radius: 0 0 $base-radius $base-radius; }
		}
		LABEL {
			display: block; overflow: hidden;
			INPUT {
				position: absolute; right: 100%; bottom: 100%;
				&:checked + SPAN {
					z-index: 2;
					&:before {
						content: ""; width: 6px; height: 10px; position: absolute; left: 9px; top: 9px; box-sizing: border-box; border: solid $color-link; border-width: 0 2px 2px 0;
						transform: translate(-40%, -50%) rotate(45deg);
					}
					&:after { box-shadow: inset 0 0 0 1px $color-link; }
				}
			}
			SPAN {
				flex: 1 1 auto; display: inline-block; overflow: hidden; padding: 3px 0 3px 30px; cursor: pointer; position: relative; z-index: 0;
				&:hover { color: $color-link; }
				&:after { content: ""; width: $gridunit; height: $gridunit; background-color: $color-white; border-radius: 50%; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); position: absolute; left: 0; top: 0; z-index: -1; }
			}
		}
	}

	TR:not(.cart-table-subproduct) + TR:not(.cart-table-subproduct) {
		TH, TD { border-top: 1px solid $color-gray-light; }
	}

	&-subproduct {
		TH, TD { border-top: 1px solid $color-gray-lightest; }
	}
	&-subproduct &-title { font-size: $font-size-medium; }

	&-specs {
		margin: 10px auto 0 0;
		> LI {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			.label { flex: 0 0 1$gridunit; opacity: 0.5; }
			.value { flex: 1 1 auto; }
		}
	}

	&-price {
		white-space: nowrap; background-color: rgba($color-black, 0.03); padding: 15px $gridunit; border-radius: $base-radius; overflow: hidden; font-size: $font-size-medium;
		> LI {
			+ LI { margin-top: 5px; }
			display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between;
			.label { flex: 0 0 auto; padding-right: $gridunit; }
			.value {
				flex: 0 0 auto;
				display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;justify-content: flex-start;
				.number { flex: 0 0 auto; text-align: center; padding: 0 10px; }
				&-button {
					flex: 0 0 auto; display: block; box-shadow: inset 0 0 0 1px $color-gray-light; width: $gridunit; height: $gridunit; box-sizing: border-box; border-radius: $base-radius; text-align: center; line-height: $gridunit; color: inherit; background-color: $color-white; font-size: 12px; font-weight: $bold;
					&:hover { text-decoration: none; color: $color-link; box-sizing: inset 0 0 0 1px $color-link; box-shadow: inset 0 0 0 1px $color-link; }
				}
			}
			&.total { font-weight: $bold; }
		}
	}

	&-remove { margin-top: 10px !important; font-size: $font-size-small; text-align: center; }
	.sum { white-space: nowrap; text-align: right; }

	@include mobile {
		margin: 0;
		TR { display: flex; flex-direction: column; }
		TH, TD {
			padding: $gridunit 0 0 0; border: none !important;
		}
		&-image { display: flex; align-items: center; justify-content: center; }
		.image { width: 100px; height: 100px; }
	}

	.cart-callout { margin-top: 8px !important; padding: 10px $gridunit; }
}

.cart-block {
	padding: $base-padding; margin: $base-margin; border-radius: $base-radius; overflow: hidden; box-shadow: $shadow01; background-color: rgba($color-white, 0.5);
	&.primary { background-color: $color-white; }
	H2 { font-size: $font-size-medium; margin: $base-margin; }
	.cart-button { padding: 0 $gridunit; }
}

.cart-prices {
	margin: $base-margin;
	> LI { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; }
	.primary { font-weight: $bold; font-size: 18px; margin-top: 8px; }
	.label { flex: 1 1 auto; }
	.value { flex: 0 0 auto; padding-left: 8px; }
}

.cart-buttons {
	@include defaultMargin(8px);
	> LI {
		margin: 8px 0 0 0;
	}
}

.cart-button {
	font-weight: 400; font-size: 18px; background-color: $color-white; border: none; outline: none; color: $color-link; padding: 0 50px; text-align: center; line-height: 40px; cursor: pointer; border-radius: $base-radius; margin: 0; display: block; width: 100%; box-sizing: border-box; white-space: nowrap; box-shadow: inset 0 0 0 1px $color-link;
	&.primary {
		background-color: $color-link; color: $color-white; box-shadow: none;
		&:hover { background-color: $color-link-hover; text-decoration: none; }
	}
}

.cart-callout {
	padding: $gridunit / 2 $gridunit; border-radius: $base-radius; margin: $base-margin !important; text-align: center; font-weight: $medium;
	& + & { margin-top: 8px !important; }

	&.intent-danger { color: $color-danger; background-color: rgba($color-danger, 0.1); }
	&.intent-warning { color: $color-warning; background-color: rgba($color-warning, 0.1); }
	&.intent-success { color: $color-success; background-color: rgba($color-success, 0.1); }
	&.intent-promotion { color: $color-link; background-color: rgba($color-link, 0.1); }
}

// Generic callout

.generic-callout {
	padding: 0 $spacer-desktop; position: relative; color: $color-gray-dark; text-align: center;
	font-weight: $medium;

	@include mobile { padding: 0 $spacer-mobile; }
	@include tablet { padding: 0 $spacer-tablet; }

	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit / 2 0;
		P { margin: 0; }
	}

	background-color: $color-gray-lighter;

	&.intent-danger {
		color: $color-white; background: $color-danger;
		A { color: $color-white; text-decoration: underline; }
	}
	&.intent-warning {
		color: $color-white; background: $color-warning;
		A { color: $color-white; text-decoration: underline; }
	}
	&.intent-success {
		color: $color-black; background: $color-success;
		A { color: $color-black; text-decoration: underline; }
	}
	&.intent-promotion {
		color: $color-white; background: $color-link-hover;
		A { color: $color-white; text-decoration: underline; }
	}

	& + & {
		margin-top: 1px;
	}
}

// Generic message

.generic-message {
	flex: 1 0 auto; text-align: center; font-size: $font-size-medium; line-height: 1.5;
	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit-large;
		P { margin: 0; }
	}
	&__title { font-size: $font-size-large; font-weight: $bold; }
}

// Footnote

.footnote {
	font-size: $font-size-smaller; color: $color-gray-medium; border-top: 1px solid $color-gray-lighter; line-height: 1.5; margin: 50px 0 0 0;
}

//

.generic-actions {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 10px -12px 0 -12px;
	> * {
		margin: 10px 12px; position: relative;
		&:not(:first-child):before { content: "|"; position: absolute; left: -12px; top: 50%; color: $color-gray-light; pointer-events: none; transform: translate(-50%, -50%); }
	}
	.intent-danger { color: $color-danger; }
}

// Cookie message

.cookiemessage {
	background-color: $color-white; color: $color-gray-dark;
	position: sticky; bottom: 0; padding: 0 $spacer-desktop; z-index: 16000003; // zopim + 1
	&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: 0 0 10px 0 rgba($color-black, 0.1); pointer-events: none; }

	@include mobile { padding: 0 $spacer-mobile; }
	@include tablet { padding: 0 $spacer-tablet; }

	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit / 2 0 $gridunit 0; text-align: center; font-size: $font-size-small;
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: space-between;
		@include mobile { display: block; }
	}

	.content { flex: 0 1 auto; line-height: $gridunit; margin: $gridunit / 2 0 0 0; }
	.actions {
		margin: $gridunit / 2 0 0 0; flex: 0 1 auto; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
		A {
			flex: 0 0 auto; background-color: $color-link; color: $color-white; line-height: $gridunit; padding: 0 10px; border-radius: $base-radius; margin: 0 3px; font-size: $font-size-small; font-weight: $medium;
			&:hover { text-decoration: none; background-color: $color-link-hover; }
		}
	}
}

// Results list

.list-results {
	@include defaultMargin; line-height: $gridunit;

	> LI {
		margin-top: $gridunit / 4; box-shadow: $shadow01; border-radius: $base-radius; overflow: hidden; cursor: pointer;
		&.replacement { margin-top: 1px; }
		&:first-child { margin-top: 0; }
	}

	.replacement &__result {
		background-color: $color-gray-lightest !important;
	}

	&__result {
		display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; align-items: center; background-color: $color-white;
		padding: 8px 8px 8px 13px;

		&.intent-danger {
			color: $color-danger;
		}
	}

	&__arrow {
		margin: 0 12px 0 0; flex: 0 0 auto; width: $gridunit-large; height: $gridunit-large; border-radius: 50%; box-shadow: inset 0 0 0 1px $color-black; display: flex; align-items: center; justify-content: center;
		svg { width: 12px; height: 12px; }
		path { fill: $color-link; }
	}
	&__result.intent-danger &__arrow path { fill: $color-danger; }

	&__main {
		flex: 1 1 auto; margin: 0 $gridunit 0 0;
	}
	&__price {
		flex: 0 0 120px; margin: 0 $gridunit 0 0; text-align: right;
	}
	&__delivery {
		flex: 0 0 100px; margin: 0 $gridunit 0 0; text-align: right;
	}

	&__cart {
		flex: 0 0 150px;
		.control-input-input { padding: 0 !important; }
	}
	&__button {
		flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap;
		.button,
		.button-icon { margin: 0 0 0 $gridunit / 4; flex: 0 0 auto; }
	}

	&__availability {
		background-color: rgba($color-white, 0.3); @include defaultPadding; position: relative; overflow: hidden;
		&:before {
			content: ""; position: absolute; left: 0; right: 0; top: 0; height: 10px; background: linear-gradient(to bottom, rgba($color-black, 0.1), rgba($color-black, 0));
		}
		TH {
			width: 1%; white-space: nowrap; text-align: right !important; min-width: 0 !important;
		}
		TD { white-space: normal !important; }
	}

	@include not-desktop {
		&__result { flex-wrap: wrap; padding: $gridunit / 2; position: relative; align-items: flex-start; }
		&__arrow { position: absolute; left: $gridunit / 2; top: $gridunit / 2; }
		&__main { flex: 1 1 100%; padding-left: $gridunit * 2; box-sizing: border-box; margin: 0; text-align: left; }
		&__cart { margin: 0; padding: $gridunit / 2 $gridunit / 2 0 0; flex: 0 0 45%; box-sizing: border-box; }
		&__button {
			margin: 0; padding: $gridunit / 4 0 0 0; flex: 0 0 55%; overflow: hidden; box-sizing: border-box; flex-wrap: wrap;
			.button { width: auto; flex: 1 0 auto; }
			.button,
			.botton-icon { margin-top: $gridunit / 4; }
		}

		&__delivery { text-align: left; }
		&__price,
		&__delivery {
			flex: 0 0 50%; margin: $gridunit / 4 0 0 0; padding: $gridunit / 4 0 0 0; border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}

	.control-input {
		$h: $input-height;
		&-addon,
		&-addon-front {
			min-width: $h; padding: 0; margin: 0;
			A,
			BUTTON,
			.button-icon { min-width: $h; }
			.button-icon-main {
				width: $h; height: $h;
				svg {
					width: 10px; height: 10px;
					&.cart2 { width: 12px; height: 12px; }
				}
			}
		}
		&.align-center { text-align: center; }
	}
	.label-primary {
		font-weight: $bold;
		DEL { font-weight: $normal; font-size: 80%; text-decoration: none; color: $color05; }
	}
	.label-secondary {
		opacity: 0.7; font-size: 12px;
	}
}

// Ads list

.list-offers {
	display: flex; flex-direction: row; flex-wrap: wrap;
	margin: 0 $gridunit / -2 0 $gridunit / -2;
	> LI {
		flex: 1 1 50%; box-sizing: border-box; padding: $gridunit $gridunit / 2 0 $gridunit / 2;
		@include mobile {
			flex: 0 0 100%;
		}
		A {
			display: block; padding: 40% 0 0 0; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; border-radius: $base-radius; box-shadow: $shadow01;
			IMG { display: none; }
		}
	}
}

//

.box-well {
	@include defaultPadding; @include defaultMargin; @include clearfix; background-color: $color-white; border-radius: $base-radius; overflow: hidden; box-shadow: $shadow01;
}

// Search results toolbar

.product-toolbar {
	$h: $input-height;
	display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; font-size: $font-size-smaller;

	@include mobile { flex-direction: column; align-items: stretch; justify-content: stretch; margin-top: $gridunit / 2; }

	.group {
		flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;
		margin-top: $gridunit;
		@include mobile { margin-top: $gridunit / 2; display: block; }
	}
	.label {
		display: block; padding: 5px 0; margin: 0 7px 0 0; opacity: 0.5;
	}

	LABEL { flex: 0 0 auto; display: flex; flex-direction: column; }

	SELECT {
		border: 0 none; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); font-size: inherit; appearance: none; outline: none;
		padding: 0 30px 0 10px; height: $h; line-height: $h; border-radius: $base-radius; margin: 0; box-sizing: border-box; min-width: 200px;
		background: $color-white url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
		&::-ms-expand {
			display: none;
		}
		@include mobile {
			width: 100%;
		}
	}
}
