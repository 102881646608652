@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/popover/common";

@import "mixins";
@import "variables";

.frame-eparts {
	flex: 1 0 auto; box-sizing: border-box; min-height: 100vh; min-height: -webkit-fill-available;
	display: flex; flex-direction: column;

	.userhub {
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; margin: 0; line-height: $gridunit; text-transform: uppercase;

		LI { margin-left: 20px; flex: 0 0 auto; }
		.#{$ns}-popover-wrapper,
		.#{$ns}-popover-target { display: block; position: relative; }
		A {
			color: inherit; text-decoration: none; display: flex; align-items: center; justify-content: center; position: relative; padding: 15px 0;
			.icon {
				flex: 0 0 auto; display: block; width: 14px; height: 14px; overflow: hidden; margin: -3px 7px -3px 0;
				img { width: 100%; height: auto; float: left; }
				@include mobile { margin: 0; }
			}
			.amount { margin-left: 7px; color: $color-danger; font-weight: $bold; }
			.products { display: none; }
			.#{$ns}-popover-target { display: block; position: relative; }
			&:hover { color: $color-link; }

			&.desktop {
				position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 2;
				@include not-desktop { display: none; }
				&:hover + * {
					color: $color-text-dark;
					.icon { opacity: 1; }
				}
			}
		}

		UL { display: none; }

		@include mobile {
			.label, .amount { display: none; }
			LI { margin: 0; }
			A {
				padding: 10px; position: relative;
				.products {
					position: absolute; right: 0; top: 0;
					background-color: $color-danger; color: $color-white;
					font-size: 9px; line-height: 16px; padding: 0 3px; min-width: 16px; text-align: center; border-radius: 8px; box-sizing: border-box;
					display: flex; align-items: center; justify-content: center;
				}
			}
		}
		@include not-mobile {
			.link-search { display: none; }
		}
	}

	&__pre-header {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 0 0 auto; box-sizing: border-box; background-color: $color-black; color: $color-white;
		padding: 0 $spacer-desktop; font-size: 13px; line-height: 25px;

		A {
			color: currentColor;
			&:hover { color: $color-link; text-decoration: none; }
		}

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}

		&__languages {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			> A { margin: 0; padding: 0 5px; display: flex; align-items: center; justify-content: center; }
			IMG { width: 17px; height: auto; margin: auto 0; }
		}
		&__links {
			display: flex; flex-direction: row; flex-wrap: nowrap; font-weight: $medium; text-transform: uppercase;
			> A { margin: 0; padding: 5px; display: flex; align-items: center; justify-content: center; }
		}

		@include mobile {
			padding: 5px $spacer-mobile;
		}
		@include tablet {
			padding: 5px $spacer-mobile;
		}
	}

	&__header {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 0 0 auto; box-sizing: border-box; position: sticky; z-index: 10; top: 0; background-color: $color-white; box-shadow: $shadow01;
		padding: 0 $spacer-desktop;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; justify-content: space-between; }
			@include mobile {
				display: flex; flex-direction: row; align-items: stretch; justify-content: space-between;
			}
		}

		.logo {
			padding: 25px 60px 25px 0; display: flex; align-items: center; justify-content: center;
			img { width: 100px; height: 30px; display: block; }

			@include not-desktop { padding: 0; margin-right: auto; }
			@include mobile {
				padding: 5px;
				IMG { width: auto; height: 30px; }
			}
			@media only screen and (max-width: 360px) {
				IMG { height: 20px; }
			}
		}

		.userhub {
			A {
				.icon {
					float: left; width: 20px; height: 20px;
					IMG { transform: translate(0, -50%); }
				}
			}
		}

		@include not-desktop {
			padding: $spacer-mobile;
			.site-menu { order: 1; }
			.logo { order: 2; }
			.userhub { order: 3; }
		}
		@include mobile {
			padding: 5px 10px;
		}
	}

	&__submenu {
		background-color: $color-gray-lightest; width: 100%; overflow: hidden; flex: 0 0 auto; border-bottom: 1px solid $color-gray-lighter;
		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; overflow: hidden;
			UL.categories {
				flex: 0 0 auto; margin: 0; box-sizing: border-box; text-align: center;
				overflow: hidden; overflow-x: auto; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto; padding: 15px 0; white-space: nowrap;
				> LI { width: 100px; text-align: center; font-size: 11px; line-height: 14px; box-sizing: border-box; padding: 0 10px; display: inline-block; vertical-align: top; }
				A {
					color: $color-gray-dark; display: block; white-space: normal;
					.image { display: block; padding: 55% 0 0 0; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; margin-bottom: 5px; }
					.label { display: block; }
					.cat-label { display: block; margin: 0; vertical-align: 0; }
					&:hover { text-decoration: none; color: $color-link; }
				}
			}
		}
	}

	&__body {
		flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; @include defaultPadding; background-color: $color-gray-lightest;
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; }

		&.gray { background-color: $color-gray-lighter; }
	}

	&__footer {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-black; color: $color-white; font-size: 14px; font-weight: 300;

		H1, H2, H3, H4, H5, H6 { color: $color-white; font-size: inherit; font-weight: $bold; @include defaultMargin(); }
		A {
			color: inherit;
			&:hover { color: $color-white; }
		}

		.column { overflow: hidden; position: relative; }

		.logo {
			display: flex;
			IMG {
				width: 100px;
			}
		}

		@include mobile {
			padding: $gridunit;
		}
		@include not-mobile {
			display: flex; flex-direction: row; flex-wrap: nowrap;

			.column { flex: 1 1 25%; padding-top: $gridunit; padding-right: $gridunit; box-sizing: border-box; }

			H3 { font-size: $font-size-large; }
			H4 { font-size: $font-size-medium; }
			H5, H6 { font-size: inherit; font-weight: 400; }
			P { margin: 10px 0 0 0; }
		}


		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: stretch; }
		}

		.group {
			INPUT[type="checkbox"] { display: none; }
			@include mobile {
				LABEL {
					display: block; border-top: 1px solid rgba($color-white, 0.2); background-color: $color-black; z-index: 2; cursor: pointer; position: relative;
					margin: -$gridunit 0 0 0; padding: $gridunit / 2 0;
					&:before { content: "+"; float: right; transition: all $uispeed $uieasing 0ms; font-weight: $bold; }
				}
				INPUT[type="checkbox"] {
					~ .group-content { transform: translateY(-100px); pointer-events: none; position: absolute; left: 0; top: 100%; right: 0; width: auto; z-index: -1; margin: 0; padding: 0 0 $gridunit 0; }
					&:checked {
						~ .group-content { transform: none; pointer-events: auto; position: static; z-index: 0; transition: all $uispeed $uieasing 0ms; }
						~ * LABEL:before { transform: rotate(45deg); }
					}
				}
			}
			@include not-mobile {
				INPUT[type="checkbox"] + * { display: none; }
			}
		}

		UL.nav {
			padding: 0; margin: 15px 0 0 0;
			> LI { margin: 5px 0 0 0; }
		}

		&__social {
			display: flex; flex-direction: row; flex-wrap: wrap;
			> LI {
				margin: $gridunit $gridunit 0 0;
				A {
					display: flex; align-items: center; justify-content: center; width: 36px; height: 36px; border-radius: 50%; background-color: $color-white;
					&:hover { background-color: rgba($color-white, 0.8); }
				}
			}
			IMG { width: 16px; height: 16px; }
		}

		&__newsletter {
			.entry {
				$h: 40px;
				@include defaultMargin();
				LABEL {
					display: block; line-height: inherit; font-size: inherit; margin: 0 0 5px 0;
					&:before { display: none; }
				}
				.field {
					display: flex; flex-direction: row; flex-wrap: nowrap; background-color: $color-white; border-radius: $base-radius; overflow: hidden;
					INPUT { flex: 1 1 auto; background-color: transparent; border: none; width: 100%; box-sizing: border-box; padding: 0 10px; box-shadow: none; color: inherit; font-size: 16px; line-height: $h; height: $h; outline: none; }
					BUTTON { flex: 0 0 auto; display: block; padding: 0 15px; margin: 4px; background-color: $color-gray-lightest; border: none; outline: none; line-height: $h - 8; height: $h - 8; font-weight: $bold; font-size: 12px; border-radius: $base-radius; cursor: pointer; }
				}
			}
		}
	}

	&__pre-cart {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-white;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include mobile { text-align: center; }
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
		}

		&__image {
			position: relative; @include defaultMargin();
			@include not-mobile { margin-right: $gridunit; flex: 0 0 110px; }
			DIV {
				padding: 66% 0 0 0; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain;
				@include mobile { height: 100px; padding: 0; }
			}
		}

		&__product {
			flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; @include defaultMargin();
		}

		&__title {
			font-weight: $bold; font-size: $font-size-medium;
		}

		&__message {
			color: $color-success;
		}

		&__actions {
			@include defaultMargin();
			@include not-mobile { flex: 0 0 150px; margin-left: $gridunit; }
			.cart-buttons {
				margin: 0;
				> LI:first-child { margin-top: 0; }
			}
		}
	}

	&__copyright {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box;
		background-color: $color-black; color: $color-white; font-size: 13px;

		H1, H2, H3, H4, H5, H6 { color: $color-white; }
		A { color: inherit; }

		.logo {
			flex: 0 0 auto;
			IMG { width: 150px; height: auto; }
		}

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; }
			@include mobile { text-align: center; }
		}

	}

	&__illustration { background: 50% 50% no-repeat; background-size: cover; background-color: $color-gray-lightest; padding-top: 40%; margin-top: 50px; }

	&__page-header {
		flex: 0 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; @include defaultPadding; padding-top: $gridunit / 2 !important; padding-bottom: 0 !important; background-color: $color-gray-lightest;
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; }

		H1 {
			margin-top: 30px;
			@include mobile { margin-top: 10px; }
		}

		.breadcrumb {
			font-size: $font-size-smaller; line-height: 1.4; display: flex; flex-direction: row; justify-content: space-between; color: $color-gray-medium; white-space: nowrap;
			.back {
				margin: 5px 0 0 0; position: relative;
				&:after { content: ""; position: absolute; right: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(-135deg) scale(0.8); transform-origin: 50% 50%; }
				@include mobile { display: none; }
			}
			.path {
				display: flex; flex-direction: row;
				> LI {
					margin: 5px 10px 0 0;
					&:not(:last-child) {
						A { margin-right: 10px; }
						&:after { content: "›"; opacity: 0.6; }
					}
				}
			}
			A {
				color: inherit; text-decoration: none;
				&:hover { color: $color-link-hover; }
			}
			@include mobile {
				font-size: $font-size-smallest;
				.path > LI {
					margin-right: 5px;
					&:not(:last-child) {
						A { margin-right: 5px; }
					}
				}
			}
		}
	}

	&__product-header {
		display: flex; flex-direction: column; flex: 0 0 auto; box-sizing: border-box; position: sticky; z-index: 8; top: 105px;
		padding: 0 $spacer-desktop; backdrop-filter: saturate(180%) blur(20px);
		background-color: rgba($color-white, 0.72);
		&.dark { background-color: rgba(29, 29, 31, 0.72); color: $color-white; border-bottom: 1px solid rgba($color-white, 0.1); }

		@include mobile { top: 50px; padding: 0 $spacer-mobile; }
		@include tablet { top: 76px; padding: 0 $spacer-tablet; }

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; position: relative; padding: 15px 0;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}

		&__button {
			cursor: pointer; display: block; text-align: center; white-space: nowrap; margin-left: $gridunit; flex: 0 0 auto;
			background: $color-link; color: $color-white; font-size: 12px;
			font-weight: 400; padding: 0 11px; border-radius: 12px; line-height: 24px;
			&:hover { background-color: $color-link-hover; text-decoration: none; }

			&[disabled],
			&.disabled { opacity: 0.5; pointer-events: none; }
		}

		H1 { font-size: 20px; margin: 0; }
	}
}

.global-frame-eparts {
	padding: 0; margin: 0; background-color: $color-white;
	BODY { padding: 0; margin: 0; }
}

// Overlay placement

.global-frame-eparts {
	@include not-desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { top: 0; }
	}
	@include desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { left: 0; }
		.#{$ns}-overlay .overlay-focusview-holder { padding-left: 0; }
	}
}

// Site menu

.site-menu {
	flex: 1 0 auto; display: flex; align-items: center;

	@include not-desktop { flex-direction: row; align-items: center; flex: 0 0 auto; }

	.menutoggler {
		font-size: 0; color: rgba($color-black, 0); padding: 10px; margin-right: 10px; box-sizing: border-box; position: relative;
		@include desktop { display: none; }
		@include tablet { margin-right: 20px; }
		span {
			display: block; width: 20px; height: 2px; background-color: $color-black; position: relative;
			&:before, &:after { content: ""; height: 2px; position: absolute; left: 0; right: 0; background-color: $color-black; }
			&:before { top: -6px; }
			&:after { bottom: -6px; }
		}
		&:hover {
			opacity: 1;
		}
	}
	NAV, NAV > UL, NAV > UL > LI { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; }
	NAV {
		font-weight: $medium; font-size: 15px; text-transform: uppercase;
		@include not-desktop { display: none !important; }
		UL { padding: 0; margin: 0; }
		LI {
			padding: 0; margin: 0 !important;
			&:before { display: none; }
		}
		> UL {
			list-style: none;
			> LI {
				padding: 0 24px 0 0;
				&:last-child { padding-right: 0; }
				> A { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; }
			}
			A {
				display: block; padding: 10px 0; position: relative; color: $color-black;
				.label {}
				.#{$ns}-popover-target {
					&:before { content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 2px; background-color: $color-link; opacity: 0; }
					&.#{$ns}-popover-open:before { opacity: 1; }
				}
				&:hover {
					color: $color-link; text-decoration: none;
					.#{$ns}-popover-target {
						&:before { opacity: 1; }
					}
				}
			}
			IMG { width: 100px; }
			UL { display: none; }
		}
	}

	.#{$ns}-popover-target { display: block; position: relative; }

	&-portal {
		.#{$ns}-transition-container { z-index: $modalZ + $pt-z-index-overlay; }
	}

	&-popover {
		display: block; z-index: $modalZ + $pt-z-index-overlay; overflow: hidden;

		.#{$ns}-popover-content {
			max-width: 100%; padding: 0;
			@include clearfix;
			@include mobile { max-width: calc(100vw - 40px); }
			&:focus { outline: none; }
			*:focus { outline: none; }
		}
		.#{$ns}-transition-container {
			display: flex; z-index: $modalZ + $pt-z-index-overlay; padding-bottom: $gridunit / 2;
			&:focus { outline: none; }
			&.#{$ns}-popover-leave .#{$ns}-popover-content { pointer-events: none; }
			&[data-x-out-of-boundaries] { display: none; }
		}

		&:focus { outline: none; }

		.#{$ns}-overlay-inline { display: inline; overflow: visible; }
		.#{$ns}-popover {
			&-target { position: relative; display: block; }
		}
		.#{$ns}-submenu {
			.#{$ns}-popover {
				padding-top: 5px;
			}
		}

		.#{$ns}-menu {
			background-color: $color-white; border: solid $color-gray-light; border-width: 0 1px 1px 1px; border-radius: 0; font-size: 13px; text-transform: uppercase; font-weight: $medium;
			> LI { border-top: 1px solid $color-gray-light; min-width: 200px; }
			&-item {
				background-color: transparent; padding: 15px 20px; cursor: pointer;
				&:hover { text-decoration: none; background-color: $color-gray-lightest; }
				&.important { font-weight: $bold; color: $color-link; }
			}
		}
	}

	&-mobile {
		text-transform: uppercase; font-weight: $medium;
		& &-heading {
			&.right { flex-direction: row-reverse; }
			background-color: $color-black; color: $color-white; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
			BUTTON {
				cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
				svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
				path { fill: currentColor; }
			}
			.logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
		}
		& &-content {
			padding: 0 20px 20px 20px;
			UL {
				padding: 1px 0 0 0; margin: 0; list-style: none; position: relative; box-sizing: border-box; min-width: 0;
				&:before { content: ""; width: auto; height: 1px; opacity: 0.15; background-color: currentColor; position: absolute; left: 0; right: 0; bottom: auto; top: 0; transform: none; border-radius: 0; }
				LI {
					padding: 0 0 1px 0; margin: 0; position: relative;
					&:before { content: ""; width: auto; height: 1px; opacity: 0.15; background-color: currentColor; position: absolute; left: 0; right: 0; bottom: 0; top: auto; transform: none; border-radius: 0; }
				}
				A { display: block; padding: 10px 0; color: inherit; text-decoration: none; }
				UL {
					padding: 0 0 0 20px;
					> LI:last-child {
						padding: 0;
						&:before { display: none; }
					}
				}
			}
		}
	}
}

.overlay-mainmenu {
	&-content { background-color: $color-black; color: $color-white; }
}
