// Units

$gridunit: 20px;
$gridunit-small: 10px;
$gridunit-large: 30px;
$uispeed: 250ms;
$uieasing: cubic-bezier(0.42, 0, 0.58, 1);
$base-radius: 6px;
$content-maxwidth: 1144px;

$spacer-desktop: 30px;
$spacer-desktop-vertical: 30px;
$spacer-tablet: 30px;
$spacer-tablet-vertical: 30px;
$spacer-mobile: 20px;
$spacer-mobile-vertical: 20px;

$grid-gap: 24px;
$column-width: 78px;

// Colors
$color01: #000;
$color02: #00adef;
$color03: #e1eaef;
$color04: #f3f6fa;
$color05: #FB3664;
$color06: #009bd6;
$color07: #009bd6;
$color08: #fbac36;

$color-white: #fff;
$color-black: #000;
$color-link: $color02;
$color-link-hover: $color06;
$color-disabled: rgba($color-black, 0.5);
$color-text-medium: lighten($color-black, 30);
$color-text-dark: $color-black;
$color-text-light: $color-white;

$color-gray-lightest: #fafafa;
$color-gray-lighter: #ededed;
$color-gray-light: #dbdbdb;
$color-gray-medium: #8b8b8b;
$color-gray-dark: #404040;
$color-gray-darker: #333;
$color-gray-darkest: #222;

$color-primary: $color02;
$color-success: #4FD966;
$color-warning: $color08;
$color-danger: $color05;
$color-disabled: $color-gray-medium;
$color-active: $color-link;

// Sizes

$mobile-header-height: 50px;
$comment-width: 280px;

$input-height: 40px;
$input-background: $color-white;
$input-background-focus: $color-white;
$input-border-color: rgba($color-black, 0.2);
$input-border-color-hover: rgba($color-black, 0.25);
$input-border-color-focus: rgba($color-black, 0.30);

$button-default-height: $input-height;
$button-small-height: $gridunit;

$base-padding: 0 $gridunit $gridunit $gridunit;
$base-padding-large: 0 $gridunit-large $gridunit-large $gridunit-large;

$base-padding-mobile: $spacer-mobile-vertical - $gridunit $spacer-mobile $spacer-mobile-vertical $spacer-mobile;
$base-padding-tablet: $spacer-tablet-vertical - $gridunit $spacer-tablet $spacer-tablet-vertical $spacer-tablet;
$base-padding-desktop: $spacer-desktop-vertical - $gridunit $spacer-desktop $spacer-desktop-vertical $spacer-desktop;

$base-margin: $gridunit 0 0 0;
$base-margin-large: $gridunit-large 0 0 0;

$base-font-size: 14px;
$font-size-smallest: 10px;
$font-size-smaller: 12px;
$font-size-small: 13px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-larger: 26px;
$font-size-largest: 40px;

$base-line-height: 22px;
$line-height-small: 16px;
$line-height-medium: 26px;
$line-height-large: 30px;

$shadow01: 0 2px 4px 0 rgba($color-black, 0.1);

$shadow02: 0 $gridunit / 2 $gridunit $gridunit / -2 $color-black;
$shadow03: 0 $gridunit / 2 $gridunit / 2 $gridunit / -2 rgba($color-black, 0.2);
$shadow-menus: 0 0 0 1px $color-gray-light, 0 $gridunit / 4 $gridunit / 2 $gridunit / -4 $color-black;

$medium: 600;
$bold: 700;
$normal: 400;

$intents: (
		"primary": $color-link,
		"success": $color-success,
		"warning": $color-warning,
		"danger": $color-danger,
		"active": $color-active,
);
