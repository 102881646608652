/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */

$black: #111418 !default;

$dark-gray1: #1c2127 !default;
$dark-gray2: #252a31 !default;
$dark-gray3: #2f343c !default;
$dark-gray4: #383e47 !default;
$dark-gray5: #404854 !default;

$gray1: #5f6b7c !default;
$gray2: #738091 !default;
$gray3: #8f99a8 !default;
$gray4: #abb3bf !default;
$gray5: #c5cbd3 !default;

$light-gray1: #d3d8de !default;
$light-gray2: #dce0e5 !default;
$light-gray3: #e5e8eb !default;
$light-gray4: #edeff2 !default;
$light-gray5: #f6f7f9 !default;

$white: #ffffff !default;

$blue1: #184a90 !default;
$blue2: #215db0 !default;
$blue3: #2d72d2 !default;
$blue4: #4c90f0 !default;
$blue5: #8abbff !default;

$green1: #165a36 !default;
$green2: #1c6e42 !default;
$green3: #238551 !default;
$green4: #32a467 !default;
$green5: #72ca9b !default;

$orange1: #77450d !default;
$orange2: #935610 !default;
$orange3: #c87619 !default;
$orange4: #ec9a3c !default;
$orange5: #fbb360 !default;

$red1: #8e292c !default;
$red2: #ac2f33 !default;
$red3: #cd4246 !default;
$red4: #e76a6e !default;
$red5: #fa999c !default;

$vermilion1: #96290d !default;
$vermilion2: #b83211 !default;
$vermilion3: #d33d17 !default;
$vermilion4: #eb6847 !default;
$vermilion5: #ff9980 !default;

$rose1: #a82255 !default;
$rose2: #c22762 !default;
$rose3: #db2c6f !default;
$rose4: #f5498b !default;
$rose5: #ff66a1 !default;

$violet1: #5c255c !default;
$violet2: #7c327c !default;
$violet3: #9d3f9d !default;
$violet4: #bd6bbd !default;
$violet5: #d69fd6 !default;

$indigo1: #5642a6 !default;
$indigo2: #634dbf !default;
$indigo3: #7961db !default;
$indigo4: #9881f3 !default;
$indigo5: #bdadff !default;

$cerulean1: #0c5174 !default;
$cerulean2: #0f6894 !default;
$cerulean3: #147eb3 !default;
$cerulean4: #3fa6da !default;
$cerulean5: #68c1ee !default;

$turquoise1: #004d46 !default;
$turquoise2: #007067 !default;
$turquoise3: #00a396 !default;
$turquoise4: #13c9ba !default;
$turquoise5: #7ae1d8 !default;

$forest1: #1d7324 !default;
$forest2: #238c2c !default;
$forest3: #29a634 !default;
$forest4: #43bf4d !default;
$forest5: #62d96b !default;

$lime1: #43501b !default;
$lime2: #5a701a !default;
$lime3: #8eb125 !default;
$lime4: #b6d94c !default;
$lime5: #d4f17e !default;

$gold1: #5c4405 !default;
$gold2: #866103 !default;
$gold3: #d1980b !default;
$gold4: #f0b726 !default;
$gold5: #fbd065 !default;

$sepia1: #5e4123 !default;
$sepia2: #7a542e !default;
$sepia3: #946638 !default;
$sepia4: #af855a !default;
$sepia5: #d0b090 !default;
$pt-intent-primary: #2d72d2 !default;
$pt-intent-success: #238551 !default;
$pt-intent-warning: #c87619 !default;
$pt-intent-danger: #cd4246 !default;

$pt-app-background-color: #f6f7f9 !default;
$pt-dark-app-background-color: #1c2127 !default;

$pt-app-secondary-background-color: #ffffff !default;
$pt-dark-app-secondary-background-color: #1c2127 !default;

$pt-app-elevated-background-color: #edeff2 !default;
$pt-dark-app-elevated-background-color: #2f343c !default;

$pt-app-secondary-background-color: #ffffff !default;
$pt-dark-app-secondary-background-color: #1c2127 !default;

$pt-app-elevated-background-color: #edeff2 !default;
$pt-dark-app-elevated-background-color: #2f343c !default;

$pt-outline-color: rgba(45, 114, 210, 0.6) !default;
$pt-focus-indicator-color: rgba(33, 93, 176, 0.752) !default;
$pt-dark-focus-indicator-color: rgba(138, 187, 255, 0.752) !default;

$pt-text-color: #1c2127 !default;
$pt-text-color-muted: #5f6b7c !default;
$pt-text-color-disabled: rgba(95, 107, 124, 0.6) !default;
$pt-heading-color: #1c2127 !default;
$pt-link-color: #215db0 !default;
$pt-dark-text-color: #f6f7f9 !default;
$pt-dark-text-color-muted: #abb3bf !default;
$pt-dark-text-color-disabled: rgba(171, 179, 191, 0.6) !default;
$pt-dark-heading-color: #f6f7f9 !default;
$pt-dark-link-color: #8abbff !default;
$pt-text-selection-color: rgba(125, 188, 255, 60%) !default;

$pt-icon-color: #5f6b7c !default;
$pt-icon-color-hover: #1c2127 !default;
$pt-icon-color-disabled: rgba(95, 107, 124, 0.6) !default;
$pt-icon-color-selected: #2d72d2 !default;
$pt-dark-icon-color: #abb3bf !default;
$pt-dark-icon-color-hover: #f6f7f9 !default;
$pt-dark-icon-color-disabled: rgba(171, 179, 191, 0.6) !default;
$pt-dark-icon-color-selected: #2d72d2 !default;

$pt-divider-black: rgba(17, 20, 24, 0.15) !default;
$pt-divider-black-muted: rgba(17, 20, 24, 0.1) !default;
$pt-dark-divider-black: rgba(17, 20, 24, 0.4) !default;
$pt-dark-divider-white: rgba(255, 255, 255, 0.2) !default;
$pt-dark-divider-white-muted: rgba(255, 255, 255, 0.1) !default;

$pt-code-text-color: #5f6b7c !default;
$pt-dark-code-text-color: #abb3bf !default;
$pt-code-background-color: rgba(255, 255, 255, 0.7) !default;
$pt-dark-code-background-color: rgba(17, 20, 24, 0.3) !default;
$ns: bp5;
$bp-ns: bp5;

$pt-grid-size: 10px !default;

$icons16-family: "blueprint-icons-16" !default;
$icons20-family: "blueprint-icons-20" !default;

$pt-icon-size-standard: 16px !default;
$pt-icon-size-large: 20px !default;

$pt-font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue",
  "blueprint-icons-16", sans-serif !default;

$pt-font-family-monospace: monospace !default;

$pt-font-size: 10px * 1.4 !default;
$pt-font-size-large: 10px * 1.6 !default;
$pt-font-size-small: 10px * 1.2 !default;

$pt-line-height: 1.28581 !default;

$pt-border-radius: 2px !default;

$pt-button-height: 10px * 3 !default;
$pt-button-height-small: 10px * 2.4 !default;
$pt-button-height-smaller: 10px * 2 !default;
$pt-button-height-large: 10px * 4 !default;

$pt-input-height: 10px * 3 !default;
$pt-input-height-large: 10px * 4 !default;
$pt-input-height-small: 10px * 2.4 !default;

$pt-navbar-height: 10px * 5 !default;

$pt-z-index-base: 0 !default;
$pt-z-index-content: 0 + 10 !default;
$pt-z-index-overlay: 0 + 10 + 10 !default;
$pt-z-index-dialog-header: 0 + 10 + 10 + 10 !default;

$pt-border-shadow-opacity: 0.1 !default;
$pt-drop-shadow-opacity: 0.2 !default;
$pt-dark-border-shadow-opacity: 0.1 * 2 !default;
$pt-dark-drop-shadow-opacity: 0.2 * 2 !default;

$pt-elevation-shadow-0: 0 0 0 1px rgba(17, 20, 24, 0.15) !default;
$pt-elevation-shadow-1:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 1px 1px rgba(17, 20, 24, 0.2) !default;
$pt-elevation-shadow-2:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 1px 1px rgba(17, 20, 24, 0.2),
  0 2px 6px rgba(17, 20, 24, 0.2) !default;
$pt-elevation-shadow-3:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2),
  0 8px 24px rgba(17, 20, 24, 0.2) !default;
$pt-elevation-shadow-4:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 4px 8px rgba(17, 20, 24, 0.2),
  0 18px 46px 6px rgba(17, 20, 24, 0.2) !default;

$pt-dark-elevation-shadow-0: inset 0 0 0 1px rgba(255, 255, 255, 0.2) !default;
$pt-dark-elevation-shadow-1:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 1px 1px 0 rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-elevation-shadow-2:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 1px 1px rgba(17, 20, 24, 0.2 * 2),
  0 2px 6px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-elevation-shadow-3:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.2 * 2),
  0 8px 24px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-elevation-shadow-4:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 4px 8px rgba(17, 20, 24, 0.2 * 2),
  0 18px 46px 6px rgba(17, 20, 24, 0.2 * 2) !default;

$pt-transition-ease: cubic-bezier(0.4, 1, 0.75, 0.9) !default;
$pt-transition-ease-bounce: cubic-bezier(0.54, 1.12, 0.38, 1.11) !default;
$pt-transition-duration: 100ms !default;

$pt-input-box-shadow:
  inset 0 0 0 1px rgba(17, 20, 24, 0.2),
  inset 0 1px 1px rgba(17, 20, 24, 0.3) !default;

$pt-dialog-box-shadow:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2),
  0 8px 24px rgba(17, 20, 24, 0.2) !default;
$pt-popover-box-shadow:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2),
  0 8px 24px rgba(17, 20, 24, 0.2) !default;
$pt-tooltip-box-shadow:
  0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2),
  0 8px 24px rgba(17, 20, 24, 0.2) !default;
$pt-toast-box-shadow:
  inset 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.2),
  0 8px 24px rgba(17, 20, 24, 0.2) !default;

$pt-dark-input-box-shadow:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3) !default;
$pt-dark-input-intent-box-shadow-colors: (
  "primary": #4c90f0,
  "success": #32a467,
  "warning": #ec9a3c,
  "danger": #e76a6e,
) !default;

$pt-dark-dialog-box-shadow:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.2 * 2),
  0 8px 24px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-popover-border-color: hsl(215deg, 3%, 38%) !default;
$pt-dark-popover-box-shadow:
  0 0 0 1px hsl(215deg, 3%, 38%),
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.2 * 2),
  0 8px 24px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-tooltip-box-shadow:
  0 2px 4px rgba(17, 20, 24, 0.2 * 2),
  0 8px 24px rgba(17, 20, 24, 0.2 * 2) !default;
$pt-dark-toast-box-shadow:
  inset 0 0 0 1px rgba(255, 255, 255, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.2 * 2),
  0 8px 24px rgba(17, 20, 24, 0.2 * 2) !default;

$pt-high-contrast-mode-border-color: buttonborder;
$pt-high-contrast-mode-active-background-color: highlight;
$pt-high-contrast-mode-active-text-color: highlight;
$pt-high-contrast-mode-disabled-border-color: graytext;
$pt-high-contrast-mode-disabled-text-color: graytext;
$pt-high-contrast-mode-disabled-background-color: graytext;
